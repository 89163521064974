.cryptoListContainer {
    background-color: #2e3440;
    color: white;
    min-height: 100vh;
    padding: 2rem;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.searchInput {
    width: 100%;
    max-width: 500px;
    padding: 0.75rem;
    margin-bottom: 1.5rem;
    border-radius: 0.5rem;
    border: none;
    font-size: 1rem;
    background-color: #3b4252;
    color: white;
    outline: none;
}

.searchInput::placeholder {
    color: #a7a8a6;
}

.cryptoContainer {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.networkContainer {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    margin: 0 auto;
}

.cryptoItem {
    display: flex;
    align-items: center;
    background: #3b4252;
    padding: 1.5rem;
    min-height: 4rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
    width: 100%;
    box-sizing: border-box;
}

.networkItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #3b4252;
    padding: 2rem 5rem;
    min-height: 5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
    width: 100%;
    box-sizing: border-box;
}

.cryptoItem:hover,
.networkItem:hover {
    transform: translateY(-3px);
    background: #434c5e;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.cryptoItem:active,
.networkItem:active {
    transform: scale(0.98);
    background: #2e3440;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.cryptoIcon {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    vertical-align: middle;
}

.cryptoInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.01rem;
    justify-content: center;
    flex: 1;
}

.cryptoCode {
    font-size: 1.125rem;
    font-weight: normal;
    color: white;
    margin: 0;
    padding: 0;
    line-height: normal;
    font-weight: bold;
}

.cryptoName {
    font-size: 0.875rem;
    color: #a7a8a6;
    margin-top: 0;
}

.noResults {
    text-align: center;
    color: #a7a8a6;
    font-size: 1rem;
    margin-top: 2rem;
}

.loading,
.error {
    font-size: 1.25rem;
    margin-top: 3rem;
    text-align: center;
    color: #a7a8a6;
}

.error {
    color: #dc3545;
}

.spinner {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border: 2px solid #fff;
    border-top: 2px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-right: 0.5rem;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@media (max-width: 1024px) { /* Расширяем медиазапрос до планшетов */
    .cryptoListContainer {
        padding: 1rem;
        align-items: stretch; /* Элементы занимают всю ширину */
    }

    .title {
        font-size: 1.5rem;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .searchInput {
        padding: 1.2rem;
        font-size: 1rem;
        max-width: 100%;
    }

    .cryptoItem {
        padding: 1.5rem;
        min-height: 3.5rem;
    }

    .networkItem {
        padding: 1.5rem 2rem; /* Уменьшаем padding для естественного вида */
        min-height: 5rem;
        width: 100%;
    }

    .cryptoContainer,
    .networkContainer {
        max-width: 100%;
        width: 100%;
        margin: 0; /* Убираем margin для полной ширины */
    }

    .cryptoIcon {
        width: 2rem;
        height: 2rem;
        margin-right: 0.4rem;
    }

    .cryptoCode {
        font-size: 1.1rem;
        line-height: normal;
    }

    .cryptoName {
        font-size: 0.9rem;
    }
}