/* Основной контейнер */
.balanceContainerProfileMenu {
    background-color: #2e3440;
    color: white;
    min-height: 100vh;
    padding: 2rem;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

/* Баланс */
.balanceBalanceTitleProfileMenu {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.balanceDollarSignProfileMenu {
    font-size: 2rem;
    color: #d8dee9;
    margin-right: 0.2rem;
}

.balanceBalanceSubtitleProfileMenu {
    font-size: 1rem;
    color: #d8dee9;
    margin-bottom: 0.7rem;
    text-align: center;
}

.balanceBalanceSubtitleProfileMenu span {
    color: #a3be8c;
    font-weight: bold;
}

.balanceButtonsProfileMenu {
    display: flex;
    gap: 1.5rem;
    margin: 0.9rem 0;
    width: 100%;
    max-width: 500px;
    justify-content: space-between;
    align-self: center;
}

/* Стили для кнопок */
.balanceButton {
    background: #4c566a;
    border: none;
    padding: 1rem 1.5rem;
    border-radius: 0.75rem;
    color: white;
    font-size: 1.3rem;
    cursor: pointer;
    transition: transform 0.2s ease, background 0.2s ease, box-shadow 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    flex: 1;
    justify-content: center;
    min-width: 120px;
    min-height: 40px;
}

.balanceButton:hover {
    transform: translateY(-2px);
    background: #5e81ac;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.balanceButton:active {
    transform: translateY(0);
    background: #3b4252;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

/* Заголовок "Средства" */
.balanceSectionTitleProfileMenu {
    font-size: 1.5rem;
    margin: 1.5rem 0 0.5rem;
    width: 100%;
    max-width: 100%;
    padding-left: 0.2rem;
    text-align: left;
    align-self: center;
    color: #a7a8a6;
}

/* Список валют */
.balanceCurrencyListProfileMenu {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Элемент валюты */
.balanceCurrencyProfileMenu {
    display: flex;
    align-items: flex-start;
    background: #3b4252;
    padding: 1rem;
    border-radius: 0.5rem;
    margin: 0.4rem 0;
    gap: 1rem;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    min-width: 0;
    box-sizing: border-box;
    transition: transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease;
}

.balanceCurrencyProfileMenu:hover {
    transform: translateY(-3px);
    background: #434c5e;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    opacity: 0.95;
}

.balanceCurrencyProfileMenu:active {
    transform: scale(0.98);
    background: #2e3440;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    opacity: 1;
}

/* Внутренний контейнер для иконки, имени и суммы */
.balanceCurrencyProfileMenu > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.balanceButtonIconProfileMenu {
    width: 2rem;
    height: 2rem;
}

.balanceCurrencyIconProfileMenu {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
}

.balanceCurrencyInfoProfileMenu {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex: 1;
}

.balanceCurrencyNameProfileMenu {
    font-size: 1.125rem;
    font-weight: bold;
    font-style: normal !important;
}

.balanceCurrencyAmountProfileMenu {
    font-size: 0.875rem;
    color: #d8dee9;
    text-align: right;
    width: 100%;
}

.balanceUsdValueProfileMenu {
    color: #f3f3f3;
    display: block;
    margin-bottom: 0.25rem;
    font-size: 0.9rem;
}

.balanceAmountTextProfileMenu {
    display: block;
    font-style: italic;
    color: #a8a8a8;
    font-size: 0.84rem;
}

/* Загрузка */
.balanceLoadingProfileMenu {
    font-size: 1.25rem;
    margin-top: 3rem;
    text-align: center;
}

/* Media Queries for Responsiveness */
@media (max-width: 480px) {
    .balanceContainerProfileMenu {
        padding: 1rem;
    }

    .balanceBalanceTitleProfileMenu {
        font-size: 3rem;
    }

    .balanceDollarSignProfileMenu {
        font-size: 2.6rem;
    }

    .balanceButtonsProfileMenu {
        gap: 0.8rem;
        justify-content: center;
    }

    .balanceButton {
        width: 100%;
        padding: 0.5rem 0.75rem;
        font-size: 0.875rem;
    }

    .balanceButtonIconProfileMenu {
        width: 1.5rem;
        height: 1.5rem;
    }

    .balanceSectionTitleProfileMenu {
        font-size: 1.125rem;
    }

    .balanceCurrencyListProfileMenu {
        width: 100%;
        max-width: 100%;
        align-items: stretch;
    }

    .balanceCurrencyProfileMenu {
        padding: 1rem;
        min-width: 0;
        max-width: 100%;
        width: 100%;
    }

    .balanceCurrencyNameProfileMenu {
        font-size: 1rem;
    }

    .balanceCurrencyAmountProfileMenu {
        font-size: 0.75rem;
    }
}