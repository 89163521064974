/* Основной контейнер */
.getInfoContainerCryptoMenu {
    background-color: #2e3440;
    color: white;
    min-height: 100vh;
    padding: 1rem;
    margin-top: 1.5rem;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    gap: 0.8rem; /* Увеличиваем gap для комфортного расстояния */
}

/* Логотип валюты */
.getInfoCurrencyLogoCryptoMenu {
    width: 30%;
    max-width: 150px;
    height: auto;
    margin-bottom: 0;
}

/* Группа баланса (заголовок, сумма, USD) */
.balanceGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem; /* Увеличиваем gap для читаемости */
    text-align: center;
}

.getInfoBalanceTitleCryptoMenu {
    font-size: 2rem;
    font-weight: normal;
    margin: 0;
    line-height: 1.2; /* Увеличиваем высоту строки для читаемости */
}

.getInfoBalanceAmountCryptoMenu {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    line-height: 1.2; /* Увеличиваем высоту строки */
    color: rgb(255, 255, 255);
}

.getInfoBalanceUsdCryptoMenu {
    font-size: 1rem;
    color: #d8dee9;
    margin: 0;
    line-height: 1.2; /* Увеличиваем высоту строки */
}

/* Кнопки */
.getInfoButtonsCryptoMenu {
    display: flex;
    gap: 1rem;
    margin: 0;
    width: 100%;
    max-width: 500px;
    justify-content: center;
}

.cryptoButton {
    background: #4c566a;
    border: none;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    flex: 1;
    max-width: 150px;
    justify-content: center;
    line-height: 2.5; /* Увеличиваем высоту строки внутри кнопок */
    margin-top: 1rem;
}

.cryptoButton:hover {
    background: #5e81ac;
}

.getInfoButtonIconCryptoMenu {
    width: 1.5rem;
    height: 1.5rem;
}

/* История транзакций */
.getInfoTransactionHistoryCryptoMenu {
    width: 100%;
    max-width: 500px;
    margin-top: 1rem;
}

.getInfoTransactionHeaderCryptoMenu {
    font-size: 1.55rem;
    color: #ffffff;
    text-align: center;
    margin: 0.8rem 0; /* Увеличиваем отступы для читаемости */
    line-height: 1.2; /* Увеличиваем высоту строки */
}

.getInfoNoTransactionsCryptoMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #a7a8a6;
    text-align: center;
}

.getInfoNoTransactionsIconCryptoMenu {
    width: 180px;
    height: 180px;
    margin-top: 6.7rem;
    margin-bottom: 0.8rem; /* Увеличиваем отступ */
    filter: grayscale(50%);
}

.getInfoDateHeaderCryptoMenu {
    font-size: 14px;
    font-weight: bold;
    color: #a5a5a5;
    padding: 5px 10px;
    margin-top: 8px; /* Увеличиваем отступ */
}

.getInfoTransactionCryptoMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px; /* Увеличиваем padding для увеличения размера */
    background-color: #3b4252; /* Новый цвет фона, лучше контрастирует с #2e3440 */
    border-radius: 10px; /* Увеличиваем радиус для мягкости */
    margin: 8px 0; /* Увеличиваем margin для большего расстояния между блоками */
    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Легкая тень */
}

.getInfoTransactionCryptoMenu:hover {
    background-color: #434c5e; /* Новый цвет при наведении */
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Усиливаем тень при наведении */
}

/* Увеличиваем иконку */
.getInfoTransactionIconCryptoMenu {
    font-size: 24px; /* Увеличиваем размер иконки */
    margin-right: 10px; /* Увеличиваем отступ */
    color: #a3be8c;
}

/* Увеличиваем текст внутри блока и добавляем обрезку для длинного текста */
.getInfoTransactionContentCryptoMenu {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: 16px; /* Увеличиваем размер шрифта */
    line-height: 1.3; /* Корректируем высоту строки */
    max-width: 60%; /* Ограничиваем ширину для обрезки текста */
}

.getInfoTransactionPathCryptoMenu {
    color: #ffffff;
    font-weight: bold;
    white-space: nowrap; /* Предотвращаем перенос строк */
    overflow: hidden; /* Скрываем переполнение */
    text-overflow: ellipsis; /* Добавляем многоточие для обрезки */
}

.getInfoTransactionDateCryptoMenu {
    font-size: 14px; /* Увеличиваем размер шрифта */
    color: #aaaaaa;
    white-space: nowrap; /* Предотвращаем перенос строк */
    overflow: hidden; /* Скрываем переполнение */
    text-overflow: ellipsis; /* Добавляем многоточие для обрезки */
}

.getInfoAmountsCryptoMenu {
    text-align: right;
    font-size: 16px; /* Увеличиваем размер шрифта */
    min-width: 120px; /* Увеличиваем минимальную ширину для читаемости */
}

.getInfoPositiveCryptoMenu {
    color: #28a745;
    font-weight: bold;
}

.getInfoNegativeCryptoMenu {
    color: #dc3545;
    font-weight: bold;
}

.getInfoSmallTextCryptoMenu {
    font-size: 14px; /* Увеличиваем размер шрифта */
    color: #888888;
    white-space: nowrap; /* Предотвращаем перенос строк */
    overflow: hidden; /* Скрываем переполнение */
    text-overflow: ellipsis; /* Добавляем многоточие для обрезки */
}

.getInfoLoadingTextCryptoMenu {
    text-align: center;
    color: #aaaaaa;
    font-size: 14px;
    margin: 12px 0; /* Увеличиваем отступы */
}

.getInfoLoadingTextCryptoMenu:last-child {
    font-style: italic;
    color: #888888;
}

/* Новый контейнер для иконки и текста внизу */
.getInfoFooterPlaceholderCryptoMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem; /* Увеличиваем отступ */
    color: #a7a8a6;
    text-align: center;
}

.getInfoFooterIconCryptoMenu {
    width: 80px;
    height: 80px;
    margin-bottom: 0.8rem; /* Увеличиваем отступ */
    filter: grayscale(50%);
}

.getInfoFooterTextCryptoMenu {
    font-size: 14px;
    margin: 0;
    line-height: 1.2; /* Увеличиваем высоту строки */
}

.getInfoNoTransactionsCryptoMenu p {
    font-size: 18px;
    margin: 0;
    line-height: 1.4;
    font-style: normal;
    font-weight: 559;
}

/* Адаптивность */
@media (max-width: 480px) {
    .getInfoNoTransactionsCryptoMenu p {
        font-size: 18px;
        line-height: 1.4;
    }

    .getInfoContainerCryptoMenu {
        padding: 0.5rem;
        gap: 0.5rem; /* Увеличиваем gap для мобильных */
    }

    .getInfoCurrencyLogoCryptoMenu {
        width: 30%;
        max-width: 150px;
    }

    .getInfoBalanceTitleCryptoMenu {
        font-size: 1.6rem;
    }

    .getInfoBalanceAmountCryptoMenu {
        font-size: 1.5rem;
    }

    .getInfoBalanceUsdCryptoMenu {
        font-size: 1rem;
    }

    .getInfoButtonsCryptoMenu {
        gap: 0.5rem;
        flex-wrap: wrap;
    }

    .cryptoButton {
        padding: 0.5rem;
        font-size: 0.9rem;
        max-width: 120px;
    }

    .getInfoButtonIconCryptoMenu {
        width: 1.7rem;
        height: 1.7rem;
    }

    .getInfoTransactionHistoryCryptoMenu {
        max-width: 100%;
    }

    .getInfoTransactionHeaderCryptoMenu {
        font-size: 1.35rem;
        margin: 0.5rem 0;
    }

    .getInfoTransactionCryptoMenu {
        padding: 10px 12px; /* Уменьшаем padding для мобильных */
        border-radius: 8px; /* Уменьшаем радиус */
        margin: 6px 0; /* Уменьшаем margin */
    }

    .getInfoTransactionIconCryptoMenu {
        font-size: 20px; /* Уменьшаем иконку */
        margin-right: 8px; /* Уменьшаем отступ */
    }

    .getInfoTransactionContentCryptoMenu {
        font-size: 14px; /* Уменьшаем шрифт */
        max-width: 55%; /* Немного уменьшаем ширину для обрезки */
    }

    .getInfoTransactionPathCryptoMenu {
        font-size: 14px; /* Уменьшаем шрифт */
    }

    .getInfoTransactionDateCryptoMenu {
        font-size: 12px; /* Уменьшаем шрифт */
    }

    .getInfoAmountsCryptoMenu {
        font-size: 14px; /* Уменьшаем шрифт */
        min-width: 100px; /* Уменьшаем минимальную ширину */
    }

    .getInfoSmallTextCryptoMenu {
        font-size: 12px; /* Уменьшаем шрифт */
    }

    .getInfoFooterIconCryptoMenu {
        width: 60px;
        height: 60px;
    }

    .getInfoFooterTextCryptoMenu {
        font-size: 12px;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .getInfoCurrencyLogoCryptoMenu {
        width: 30%;
        max-width: 150px;
    }

    .getInfoButtonsCryptoMenu {
        gap: 0.6rem;
    }

    .getInfoTransactionCryptoMenu {
        padding: 12px 14px; /* Средний padding */
        border-radius: 9px; /* Средний радиус */
        margin: 7px 0; /* Средний margin */
    }

    .getInfoTransactionIconCryptoMenu {
        font-size: 22px; /* Средний размер иконки */
        margin-right: 9px; /* Средний отступ */
    }

    .getInfoTransactionContentCryptoMenu {
        font-size: 15px; /* Средний шрифт */
        max-width: 58%; /* Немного уменьшаем ширину для обрезки */
    }

    .getInfoTransactionPathCryptoMenu {
        font-size: 15px; /* Средний шрифт */
    }

    .getInfoTransactionDateCryptoMenu {
        font-size: 13px; /* Средний шрифт */
    }

    .getInfoAmountsCryptoMenu {
        font-size: 15px; /* Средний шрифт */
        min-width: 110px; /* Средняя минимальная ширина */
    }

    .getInfoSmallTextCryptoMenu {
        font-size: 13px; /* Средний шрифт */
    }

    .getInfoFooterIconCryptoMenu {
        width: 70px;
        height: 70px;
    }
}

@media (min-width: 769px) {
    .getInfoCurrencyLogoCryptoMenu {
        width: 30%;
        max-width: 150px;
    }
}