/* WithdrawPage.module.css */

/* Основной контейнер */
.withdrawContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 10px;
    background-color: #2E3440;
    color: #FFFFFF;
    font-family: Arial, sans-serif;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative; /* Для правильного позиционирования кнопки */
}

/* Контейнер для контента (заголовок и карточки) */
.contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;
}

/* Заголовок и подзаголовок */
.header {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.title {
    font-size: 1.8em;
    margin: 0;
    color: #FFFFFF;
}

.subtitle {
    font-size: 0.9em;
    color: #E45555;
    margin: 5px 0 0 0;
}

/* Обёртка для карточек */
.cardWrapper {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    padding-bottom: 20px; /* Отступ снизу для контента */
}

/* Карточка с информацией */
.card {
    background-color: #58626A;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Элемент карточки */
.cardItem {
    margin-bottom: 5px;
}

/* Метка */
.label {
    font-size: 0.9em;
    color: #959595;
    text-align: left;
    display: block;
    margin-bottom: 5px;
}

/* Строка ввода */
.inputRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2em;
    padding: 5px 0;
}

/* Информация о криптовалюте */
.cryptoInfo {
    display: flex;
    align-items: center;
}

.iconImage {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
}

.cryptoName {
    font-size: 1em;
    color: #FFFFFF;
}

/* Поле ввода суммы */
.inputAmount {
    width: 80px;
    background-color: transparent;
    border: none;
    text-align: right;
    font-size: 1.1em;
    color: #FFF;
    padding: 0;
    border-radius: 5px;
}

.inputAmount:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(51, 162, 255, 0.7);
}

/* Скрытый span для динамической ширины */
.hiddenSpan {
    visibility: hidden;
    position: absolute;
    white-space: pre;
    font-size: 1.1em;
}

/* Кастомный выпадающий список для сетей */
.networkDropdown {
    position: relative;
    width: 100%;
}

.networkSelected {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #3D474F;
    border: 1px solid #58626A;
    border-radius: 10px;
    color: #FFF;
    font-size: 1em;
    cursor: pointer;
}

.dropdownArrow {
    margin-left: auto;
    font-size: 0.8em;
}

.networkOptions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #3D474F;
    border: 1px solid #58626A;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
}

.networkOption {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.networkOption:hover {
    background-color: #58626A;
}

.networkIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

/* Текстовое поле для адреса */
.textArea {
    width: 100%;
    height: 60px;
    background-color: transparent;
    border: 1px solid #58626A;
    border-radius: 10px;
    font-size: 1.1em;
    color: #FFF;
    padding: 10px;
    box-sizing: border-box;
    resize: none;
    outline: none;
}

.textArea:focus {
    border-color: #33A2FF;
    box-shadow: 0 0 5px rgba(51, 162, 255, 0.7);
    background-color: #3D474F;
}

/* Текст баланса и ошибок */
.balanceText {
    font-size: 0.9em;
    text-align: center;
    margin: 0;
    color: #33A2FF;
}

/* Контейнер для баланса (кликабельная область) */
.balanceContainer {
    padding: 10px;
    background-color: #58626A;
    border: 1px solid #58626A;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
    text-align: center;
}

.balanceContainer:hover {
    background-color: #4A5562;
}

/* Ошибка вверху (для общих ошибок) */
.errorText {
    color: #E45555;
    font-size: 12px;
    margin-top: 5px;
    display: block;
}

.errorTop {
    text-align: center;
    color: #E45555;
    font-size: 1.2em;
    margin: 10px 0;
}

/* Контейнер кнопки */
.buttonContainer {
    width: 100%;
    max-width: 400px;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* Фиксируем кнопку внизу */
    bottom: 10px; /* Отступ от нижней части экрана */
    left: 50%; /* Центрирование */
    transform: translateX(-50%); /* Корректировка центрирования */
    z-index: 1000; /* Убеждаемся, что кнопка поверх контента */
}

/* Кнопка вывода */
.withdrawButton {
    padding: 15px 30px;
    background-color: #33A2FF;
    color: #FFF;
    border: none;
    border-radius: 10px;
    font-size: 1.2em;
    cursor: pointer;
    box-sizing: border-box;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: auto;
}

/* Эффект при наведении */
.withdrawButton:hover {
    background-color: #2B91E6;
}

/* Загрузка */
.loading {
    text-align: center;
    font-size: 1.2em;
    margin-top: 20px;
}

/* Адаптивность */
@media (max-width: 480px) {
    .withdrawContainer {
        padding: 5px;
    }

    .card {
        padding: 10px;
    }

    .inputAmount {
        font-size: 1em;
        width: 100%;
    }

    .withdrawButton {
        width: 100%;
        padding: 12px 20px;
    }

    .buttonContainer {
        bottom: 5px; /* Меньший отступ для мобильных */
        max-width: 90%; /* Уменьшаем максимальную ширину на мобильных */
    }
}

@media (max-width: 375px) { /* iPhone SE */
    .buttonContainer {
        bottom: 5px;
        max-width: 90%;
    }
}