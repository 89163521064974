.invoiceContainer {
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    background-color: #2e3440;
    color: #ffffff;
    font-family: Arial, sans-serif;
    box-sizing: border-box;
}

.title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 16px;
    text-align: center;
    text-transform: uppercase;
}

.subtitle {
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 24px;
    text-align: center;
    line-height: 1.5;
}

.subtitle span {
    color: #3a9fe5;
}

.timerContainer {
    margin-bottom: 24px;
}

.timerContainer p {
    font-size: 16px;
    margin-bottom: 8px;
    text-align: center;
}

.progressBar {
    width: 100%;
    height: 12px;
    background-color: #4c566a;
    border-radius: 12px;
    overflow: hidden;
}

.progressFill {
    height: 100%;
    transition: width 0.3s ease;
}

.qrCodeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}

.qrCodeWrapper {
    position: relative;
    display: inline-block;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 0 0 4px #e5e7eb;
}

.qrCodeWrapper canvas {
    display: block;
}

.largeLogo {
    width: 280px;
    height: 280px;
    object-fit: contain;
    background-color: #2e3440;
    border: none; /* Исправлен синтаксис */
}

.qrCodeWrapper.expired {
    background-color: transparent; /* Убираем белый фон контейнера */
    padding: 0; /* Убираем отступы, чтобы избежать лишних границ */
    box-shadow: none; /* Убираем тень */
    border: none; /* Убираем возможную границу контейнера */
}

.amountContainer {
    margin-bottom: 24px;
    text-align: center;
}

.label {
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 8px;
}

.amount {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
}

.addressContainer {
    word-wrap: break-word;
}

.address {
    font-size: 14px;
    color: #ffffff;
    background-color: #3b4252;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 12px;
    text-align: center;
    word-break: break-all;
}

.expiredMessage {
    font-size: 14px;
    color: #FC3F3F;
    background-color: #3b4252;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 12px;
    text-align: center;
}

.copyButton {
    display: block;
    width: 100%;
    background-color: #4c566a;
    color: #ffffff;
    border: none;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.copyButton:hover {
    background-color: #5e81ac;
}

.loading,
.error {
    text-align: center;
    color: #ffffff;
    font-size: 16px;
}

/* Адаптивность */
@media (max-width: 480px) {
    .invoiceContainer {
        max-width: 100%;
        padding: 16px;
        margin: 10px;
    }

    .title {
        font-size: 24px;
        margin-bottom: 12px;
    }

    .subtitle {
        font-size: 12px;
        margin-bottom: 20px;
    }

    .timerContainer p {
        font-size: 14px;
    }

    .progressBar {
        height: 10px;
    }

    .qrCodeWrapper {
        padding: 16px;
    }

    .qrCodeWrapper canvas {
        width: 240px !important;
        height: 240px !important;
    }

    .largeLogo {
        width: 280px;
        height: 280px;
        background-color: #2e3440;
        border: none; /* Исправлен синтаксис */
    }

    .amount {
        font-size: 18px;
    }

    .address {
        font-size: 12px;
        padding: 10px;
    }

    .expiredMessage {
        font-size: 12px;
        padding: 10px;
    }

    .copyButton {
        font-size: 14px;
        padding: 10px;
    }
}